import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 2000.000000 2000.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)">
	<path d="M8278 19781 c-669 -119 -1220 -219 -1223 -222 -3 -4 41 -276 99 -606
58 -329 106 -603 106 -610 0 -6 -37 -24 -82 -39 -46 -15 -159 -56 -252 -90
-92 -35 -171 -62 -175 -59 -4 2 -146 245 -316 539 l-309 535 -31 -18 c-16 -10
-500 -290 -1075 -621 -575 -331 -1049 -606 -1053 -610 -5 -4 126 -241 291
-526 164 -286 304 -529 310 -540 11 -20 -1 -32 -156 -162 -92 -77 -185 -156
-208 -176 l-40 -35 -475 399 c-261 220 -477 399 -481 400 -4 0 -132 -149 -285
-331 -153 -183 -422 -503 -598 -713 -176 -210 -412 -490 -524 -624 l-204 -244
24 -22 c13 -13 222 -189 464 -391 242 -202 446 -373 453 -380 12 -10 1 -34
-60 -136 -41 -68 -102 -174 -137 -236 l-62 -112 -107 39 c-278 99 -1001 363
-1030 376 -33 13 -33 13 -47 -19 -37 -90 -845 -2320 -842 -2324 3 -2 265 -99
583 -214 319 -116 580 -212 582 -213 1 -2 -13 -88 -32 -192 -19 -104 -40 -224
-47 -266 l-12 -78 -623 0 -624 0 0 -1255 0 -1255 623 0 624 0 22 -138 c13 -75
34 -195 47 -266 14 -70 24 -130 22 -131 -2 -2 -263 -98 -582 -214 -318 -115
-580 -212 -583 -214 -3 -4 805 -2235 842 -2324 14 -32 14 -32 48 -18 31 13
723 266 1029 376 l107 38 37 -67 c57 -103 142 -249 192 -331 44 -72 45 -74 26
-90 -217 -177 -934 -786 -932 -792 2 -4 93 -115 203 -246 110 -131 470 -559
798 -950 329 -392 601 -713 605 -713 4 1 220 180 481 400 l475 399 70 -62 c39
-33 133 -112 208 -175 127 -105 137 -116 126 -135 -6 -12 -146 -255 -310 -541
-165 -285 -296 -522 -291 -526 4 -4 478 -278 1053 -610 575 -331 1059 -611
1076 -621 l30 -18 309 535 c170 294 312 537 315 539 4 3 83 -24 176 -59 93
-35 206 -75 252 -90 45 -15 82 -33 82 -39 0 -7 -48 -281 -106 -611 -58 -329
-102 -602 -97 -606 4 -4 550 -103 1213 -220 663 -116 1214 -214 1225 -216 19
-5 25 23 114 528 51 293 100 570 109 616 l15 82 272 0 272 0 15 -82 c9 -46 58
-323 109 -616 89 -505 95 -533 114 -528 11 2 562 100 1225 216 663 117 1209
216 1213 220 5 4 -39 277 -97 606 -59 330 -106 604 -106 610 0 6 62 33 138 59
75 26 188 68 251 92 92 35 117 41 126 31 10 -11 223 -378 569 -980 l50 -87 30
18 c17 10 501 290 1076 621 575 332 1049 606 1053 610 5 4 -126 241 -291 526
-164 286 -304 529 -311 541 -11 21 -6 27 60 80 40 32 134 110 208 174 131 112
137 116 157 100 11 -9 224 -187 473 -396 249 -209 457 -381 461 -383 4 -2 66
66 137 150 70 84 430 513 798 951 369 439 671 802 673 806 2 6 -707 609 -932
792 -19 15 -17 18 41 115 34 54 95 160 136 234 52 93 81 134 91 132 17 -3
1070 -385 1124 -407 33 -14 33 -14 47 18 37 90 845 2320 842 2324 -3 2 -265
99 -583 214 -319 116 -580 212 -582 214 -2 1 8 61 22 131 13 71 34 191 47 266
l22 138 624 0 623 0 0 1255 0 1255 -623 0 -624 0 -22 138 c-13 75 -34 195 -47
266 -14 70 -24 130 -22 131 2 2 263 98 582 214 318 115 580 212 583 214 2 3
-189 534 -424 1181 -344 945 -431 1175 -443 1170 -49 -21 -1132 -414 -1146
-416 -12 -2 -32 25 -71 97 -30 55 -91 160 -135 234 -78 130 -80 135 -62 150
258 212 934 786 932 792 -2 4 -137 166 -300 361 -163 194 -522 622 -798 951
-275 328 -504 597 -508 596 -4 0 -220 -179 -481 -399 l-475 -398 -45 39 c-25
22 -119 101 -208 176 -148 124 -161 138 -151 157 6 12 146 254 310 540 165
285 296 522 291 526 -4 4 -435 254 -958 555 -522 302 -1006 581 -1075 620
-110 65 -126 71 -136 56 -6 -9 -143 -245 -304 -526 -161 -280 -298 -516 -305
-523 -9 -10 -37 -3 -141 36 -71 28 -184 69 -251 92 -68 24 -123 48 -123 54 0
6 47 280 106 610 58 329 102 602 97 606 -4 4 -550 103 -1213 220 -663 116
-1214 214 -1225 216 -19 5 -25 -23 -114 -528 -51 -293 -100 -570 -109 -615
l-15 -83 -272 0 -272 0 -15 83 c-9 45 -58 322 -109 615 -53 297 -98 532 -104
531 -5 -1 -558 -99 -1227 -218z m2062 -1791 c363 -17 639 -45 980 -101 1376
-225 2700 -830 3775 -1725 409 -341 698 -628 1032 -1024 417 -494 791 -1070
1063 -1635 119 -248 116 -241 185 -405 779 -1847 828 -3956 137 -5845 -146
-398 -336 -807 -557 -1200 -131 -234 -384 -618 -553 -840 -222 -293 -358 -454
-591 -700 -611 -646 -1340 -1197 -2121 -1603 -1665 -867 -3570 -1115 -5430
-708 -817 179 -1704 536 -2392 962 -26 16 -79 49 -119 73 -237 144 -659 453
-889 650 -75 65 -230 200 -246 216 -6 5 -62 57 -124 115 -127 118 -340 341
-509 536 -154 175 -316 379 -474 595 -77 107 -316 469 -367 559 -334 581 -533
1015 -713 1550 -538 1601 -552 3356 -41 4969 71 223 204 578 284 756 100 224
135 297 225 470 56 110 120 229 140 265 21 36 45 79 55 95 165 288 423 666
648 950 504 635 1131 1216 1792 1662 883 594 1832 994 2865 1207 643 132 1312
186 1945 156z"/>
<path d="M9679 17229 c-1025 -46 -2009 -303 -2929 -764 -163 -82 -333 -177
-540 -303 -565 -344 -1158 -842 -1611 -1352 -851 -957 -1433 -2123 -1684
-3372 -230 -1149 -178 -2354 150 -3473 271 -924 731 -1795 1338 -2535 88 -107
237 -280 267 -310 3 -3 59 -61 125 -130 710 -739 1561 -1314 2515 -1698 1541
-620 3277 -687 4867 -186 870 273 1651 693 2378 1278 232 187 407 348 669 616
640 655 1184 1507 1527 2392 614 1586 647 3355 93 4970 -193 562 -461 1109
-790 1608 -74 114 -260 378 -273 390 -4 3 -19 23 -35 45 -106 144 -337 413
-511 594 -507 528 -1024 932 -1658 1295 -1167 669 -2535 997 -3898 935z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
